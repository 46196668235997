// Darkly 5.1.3
// Bootswatch


// Variables

$web-font-path: "https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,400&display=swap" !default;
@if $web-font-path {
  @import url($web-font-path);
}

@font-face {
	font-family: Samarkan;
	src: url('/static/auth/src/fonts/SAMAN___.TTF');
}

// Typography

.blockquote {
  &-footer {
    color: $gray-600;
  }
}

.font-samarkan {
	font-family: Samarkan;
}

// Forms

.input-group-addon {
  color: $white;
}

.form-floating > label {
  color: $gray-700;
}

// Navs

.nav-tabs,
.nav-pills {
  .nav-link,
  .nav-link.active,
  .nav-link.active:focus,
  .nav-link.active:hover,
  .nav-item.open .nav-link,
  .nav-item.open .nav-link:focus,
  .nav-item.open .nav-link:hover {
    color: $white;
  }
}

.breadcrumb a {
  color: $white;
}

.pagination {
  a:hover {
    text-decoration: none;
  }
}

// Indicators

.alert {
  border: none;
  color: $white;

  a,
  .alert-link {
    color: $white;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value linear-gradient(180deg, mix($white, $value, 15%), $value) repeat-x;
      } @else {
        background-color: $value;
      }
    }
  }
}

$top-menu-height: 64px;
$footer-height: 40px;

.main-content-outer {
	padding: 0;

	.custom-navbar {
		height: $top-menu-height;
		width: 100%;
		left: 0;
	}

	.main-content-inner {
		position: fixed;
		top: $top-menu-height;
		left: 0;
		height: calc(100vh - $top-menu-height);
		width: 100%;

		.main-content {
		}
	}

	.scrollable {
		height: 100%;
	}
}

footer.custom-footer {
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	height: $footer-height;
	background-color: $success;
	background: linear-gradient(90deg, darken($success, 20%) 0%, $green 100%);
	color: $white;
}

.side-menu-bg {
	background-color: darken($blue, 50%);

	& .nav.side-menu>li>a,
	& .nav.child_menu>li>a {
		color: $white;
	}
}

.custom-navbar {
	background: $blue;
	background: linear-gradient(90deg, darken($blue, 50%) 0%, darken($blue, 20%) 100%);
	color: $white;
	position: fixed;
}

// Auth page body top level styles
.auth.body-custom {
	font-family: $font-family-sans-serif;
    font-size: 16px;
    padding: 0;
    background: linear-gradient(225deg, darken($blue, 50%), $blue);
    background-color: $yellow;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;

	.login-page {
		width: 470px;
	}
	.brand-name > .brand-name-string {
		font-size: 4rem;
		border-bottom: 1px solid $black;
		padding: 16px 0 32px;
	}
	.card {
		background-color: $yellow;
	}

	.logo {
		padding: 32px 100px 0;
	}

	.card-header {
		background-color: fade-out($white, .7);
		padding: 6px;
		border-radius: 8px;
		text-align: center;
	}

}
